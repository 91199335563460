@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
.finance-user-modal::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.finance-user-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
.finance-user-modal::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
.finance-user-modal::-webkit-scrollbar-thumb:hover {
    background: #555;
}