.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.blogContent {
  /* Default font size for all elements */
  font-size: 16px; /* Adjust this value as needed */

  /* Font size for specific elements */
  h1 {
    font-size: 2em; /* Example font size for h1 */
  }

  h2 {
    font-size: 1.8em; /* Example font size for h2 */
  }

  h3 {
    font-size: 1.6em; /* Example font size for h3 */
  }

  h4 {
    font-size: 1.4em; /* Example font size for h4 */
  }

  h5 {
    font-size: 1.2em; /* Example font size for h5 */
  }

  h6 {
    font-size: 1em; /* Example font size for h6 */
  }

  p {
    font-size: 1em; /* Example font size for paragraphs */
  }

  i {
    font-size: 1em; /* Example font size for italic text */
  }

  strong {
    font-size: 1em; /* Example font size for strong text */
  }

  ul {
    font-size: 1em; /* Example font size for unordered lists */
  }

  li {
    font-size: 1em; /* Example font size for list items */
  }

  /* Add more tags and their font sizes as needed */
}

/* Styling for pagination */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 8px 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: block;
  padding: 4px 12px;
  color: black;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination a:hover {
  background-color: #00000079;
  color: #fff;
}

.pagination .active a {
  background-color: #000000;
  color: #fff;
  pointer-events: none;
}

.pagination .disabled a {
  color: #000000 !important;
  pointer-events: none;
  opacity: 0.6;
}
